.product-card {
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    padding: 16px;
    text-align: center;
    transition: box-shadow 0.2s ease-in;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--secondary-color);
    box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;

  }
  .product-card:hover{
    
    box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.5);
  }

  
  
  .product-card img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 16px;
    border: 1pt solid var(--dark-color);    
    transition: border-radius ease 0.8s;
    z-index: 2;
    cursor: grab;

  }

  /* .product-card img:hover{
    border-top-left-radius: 60pt;    
    cursor: grab;
    
  } */

  /* .product-card::after {
    content: "Abrir";
    background: var(--primary-color);
    width: 50pt;
    height: 50pt;
    position: absolute;
    top: 13pt;
    left: 12pt;
    z-index: 1;
    padding: 3pt 2pt;
    margin: 0;
    text-align: start;
    color: var(--secondary-color);
    border: 1pt solid var(--dark-color);
    z-index: 1;
    border-radius: 4px;
    display: none;    
  } */
  .product-card h3 {
    color: var(--primary-color);
  }
  
  .product-info {
    text-align: left;
  }
  .product-card-action-butons{
    display: flex;
    flex-direction: column;
    gap: 5pt;
    width: 100%;
  }
  .product-card-action-butons a{
    font-size: 12pt;
  }
  a:link,a:visited {
    color: unset;
    text-decoration: none;
  }
  .product-card-action-butons svg{
    font-size: 14pt;
  }
  .product-more-details{
    background: var(--info-color);
    padding: 6pt;
    color: var(--secondary-color);
    border-radius: 2pt;
    box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.1);
  }
  .products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
  
  .products-grid.mobile {
    grid-template-columns: 1fr;
  }
  
  /* .product-card {
    

  } */
  
  .product-card img {
    max-width: 100%;
  }