.related-products-carousel {
    margin-top: 20px;
    color: var(--black-color);
  }
  
  .carousel-container {
    display: flex;
    overflow-x: auto;
    background: var(--secondary-color);
    padding: 10pt;
    box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.5);
  }
  .carousel-container::-webkit-scrollbar {
    height: 12pt;
  }
  .carousel-container::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 1pt;
    cursor: pointer;

  }
  
  .carousel-item {
    flex: 0 0 auto;
    width: 200pt;
    margin-right: 10pt;
    text-align: center;
    border: 1pt solid var(--dark-color);
    padding: 10pt;
    border-radius: 5pt;
  }
  
  .carousel-thumb {
    width: 100%;
    height: auto;
  }
  
  .carousel-item button {
    margin-top: 10pt;
    background-color: var(--info-color);
    color: var(--secondary-color);
    border: none;
    padding: 5pt 10pt;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .carousel-item button:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }
  