/* Filters.css */
.filters-container {
    margin-bottom: 1rem;
    
  }
  
  .filter-section {
    margin-bottom: 1rem;
    background: var(--secondary-color);
    border: 0.5pt solid var(--primary-color);
    color: var(--primary-color);
  }
  
  .filter-header {
    cursor: pointer;
    display: flex;
    font-weight: 700;
    justify-content: space-between;
    padding: 10pt 5pt;
    
  }
  
  .filter-options {
    margin-top: 0.5rem;
    text-transform: capitalize;
    
  }
  
  .filter-option {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0 5pt;
  }
  .filter-option input{ width: 15pt; height: 15pt;}
  
  .filter-option input {
    margin-right: 0.5rem;
  }