/* src/components/Header.css */
.cart-area{
  display: flex;
  align-items: center;
}
.cart-area a{
  text-decoration: none;
  color: var(--secondary-color) !important;
  display: flex;
  gap: 5pt;
  font-size: 20pt;
  align-content: center;
  align-items: flex-start;
}
.cart-area a:visited, .cart-area a:link{
  color: var(--secondary-color) !important;
}
.header {
    background-color: var(--primary-color);   
    display: flex;
    flex-direction: column; /* Ajuste para permitir que o menu expanda para baixo */
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    color: var(--secondary-color);
  }
  .linha-um{
    display: flex;
    max-height: 50pt;
    background: #d0e7ff;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10pt 0;
  }
  .linha-dois{
    background-color: var(--primary-color);
    padding: 10pt 20pt;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    justify-content: space-around;
    box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.5);

  }
  .logo {    
    margin-left: 20pt;
  }
  .contato-header span, .contato-rss{
    display: flex;
    align-items: center;
    gap: 5pt;
  }

  .contato-header svg {
    font-size: 32pt;
    color: var(--success-color);
    cursor: pointer;
  }
  

  .contato-rss button {
    border: none;
    padding: 5pt;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-radius: 2pt;
    cursor: pointer;
  }

  .contato-rss input
  {
    margin: 5pt 0;
    padding: 5pt;
    border: 1pt solid var(--primary-color);
    border-radius: 4pt;
    box-sizing: border-box;
    outline: none;
  }


  .left-header{
    display: flex;
    padding: 0 10pt;
    flex-direction: column;
    align-items: flex-start;
  }
  .right-header{
    display: flex;
    flex-direction: row;
    margin-right: 10pt;
    justify-content: flex-end;
    width: 100%;
    padding: 0 10pt;
  }
  .login-area{
    display: flex;
    border-top-right-radius: 5pt;
    border-bottom-right-radius: 5pt;
    cursor: pointer;
    border: var(--secondary-color);
    padding: 10pt 5pt;
    background: var(--primary-color);;
    align-items: center;
  }
  .login-area span{color: var(--secondary-color); }
  .login-area span:first-of-type{margin-right: 10pt;}
  .login-menu-area{
    display: none;
    }
    
  .header-form-login{
    display: flex;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    background: var(--secondary-color);
    outline: unset;
    border-radius: 5pt;
  }
  .header-form-login input:first-child{
    outline: unset;
    border: none;
    border-right: 1pt solid var(--dark-color);
    border-top-left-radius: 5pt;
    border-bottom-left-radius: 5pt;
    padding: 10pt;    
    border-right: 1pt solid var(--dark-color);   
  }
  .header-form-login input{
    outline: unset;
    border: none;     
    padding: 10pt;  
    background: var(--secondary-color);
  }  
  .logo img{
    max-width: 150pt;    
  }  
  .logo a {
    color: var(--secondary-color);
    text-decoration: none;
    font-size: 27pt;
  }  
  /* src/components/Header.css */
.logo-image {
    transition: max-width 0.4s ease-in-out;
  }
  
  .logo-image.active {
    opacity: 1;
    max-width: 200pt !important;
  }
  .menu-icon {
    font-size: 27pt;
    color: var(--secondary-color);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }  
  .nav-menu {
    overflow: hidden;
    transition: height 0.3s ease-out;
    width: 100%;
  }  
  .nav-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }  
  .nav-menu li {
    margin: 10pt 0;
  }  
  .liWidthCustom{
    min-width: 180pt;
  }
  .nav-menu a {
    color: var(--secondary-color);
    text-decoration: none;
    font-size: 18pt;
    padding: 10pt;
    display: block;
  }  

  .profile-icon {    
    position: relative;
    cursor: pointer;
    width: 50pt;
    height: 50pt;
    background: var(--info-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .profile-icon svg{font-size: 20pt;}
  
  .profile-menu {
    display: none;
    position: absolute;
    top: 55pt;
    right: 0;    
    background: var(--info-color);
    box-shadow: 0 2pt 5pt rgba(0,0,0,0.2);
    border-radius: 8pt;
    overflow: hidden;
    z-index: 9999;
    min-width: 180pt;    
  }
  
  .profile-menu.open {    
    display: block;

  }
  
  .profile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .profile-menu ul li {
    padding: 10pt 20pt;
    cursor: pointer;
  }
  
  .profile-menu ul li:hover {
    background: var(--secondary-color);
  }
  
  .profile-menu ul li button {
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding: 0;
    margin: 0;
  }
  
  .login-modal-btn-area {
    display: flex;
    align-items: center;
    padding: 0 20pt;
    color: var(--secondary-color);
  }
  .linha-um .login-modal-btn-area button
  { 
    background-color: var(--info-color);
    color: var(--secondary-color);
    cursor: pointer;
    border-radius: 4pt;
    width: 100%;
    box-sizing: border-box;
    padding: 10pt 20pt;
    border: none;
  }
  @media (max-width: 768px) {
    .login-modal-btn-area{
      justify-content: center;
    }
    .profile-menu{
      right: unset;      
    }
    .linha-um{
      max-height: unset;
    }
    .itens-linha-um{
      display: flex;
      flex-direction: column;
      gap: 10pt;
      justify-content: center;
      justify-items: center;
    }
    .contato-rss{
      display: none !important;
    }
    .logo img{
      width: calc(100% - 10pt);
      max-width: 300pt;
    }
  }


  @media (min-width: 768px) {
    
    .logo img{
        width: 135pt;    
      } 
    .login-menu-area{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        background: var(--secondary-color);
        margin-right: 30pt;
        border-radius: 5pt;
      }
    .right-header{
        display: flex;
        flex-direction: row;        
      }

    .menu-icon {
      display: none;
    }
  
    .nav-menu {
      position: static;
      height: auto !important;
      flex-direction: row;
      background-color: transparent;
      transition: none;
    }
  
    .nav-menu ul {
      flex-direction: row;
    }
  
    .nav-menu li {
      margin: 0 10pt;
    }
  
    .nav-menu a {
      font-size: 18pt;
    }

    .itens-linha-um{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: var(--primary-color);
      align-items: center;
    }
  }
  

