
.product-details {
  display: flex;
  flex-direction: column;
  padding: 20pt;
  position: relative;
  color: var(--black-color);
  gap: 20pt;
}

.product-header {
  display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10pt 10pt;
    background: var(--secondary-color);
    box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.2);
}

.back-button {
  padding: 10pt 20pt;
  background-color: var(--info-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 2pt;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: var(--primary-color);
}

.product-details-content {
  display: flex;
  flex-direction: column;
  background: var(--secondary-color);
  padding: 20pt;
  gap: 50pt;
  box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.5);

}

.image-column{
  flex: 2;  
}

.info-column {
  flex: 4;
}
.info-column h1, .info-column h2 {
  color: var(--primary-color);
  border-bottom: 2pt solid var(--primary-color); 
}

.image-container {
  max-width: 100%;
  position: relative;
  border: 2pt solid var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%;
  height: auto;
}

.image-area {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoom-image {
  position: absolute;
  width: 95%;
  height: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  display: none;
  cursor: zoom-in;
  border: 2pt dashed var(--primary-color);
}

.thumbnail-area {
  width: 100%;
}

.thumbnail-container {
  display: flex;
  overflow-x: auto;
  margin-top: 10pt;
  align-items: center;
  border-top: 2pt solid var(--dark-color);
  justify-content: space-around;
}

.thumbnail-container::-webkit-scrollbar {
  height: 8pt;
  background: var(--dark-color);
}

.thumbnail-container::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);  
}

.thumbnail {
  width: 60pt !important;
  height: 60pt !important;
  margin: 0 5pt;
  cursor: pointer;
  border-left: 2pt solid var(--dark-color);
  border-right: 2pt solid var(--dark-color);
  transition: border-color 0.3s;
}

.thumbnail:hover,
.thumbnail.active {
  border-color: var(--primary-color);
}

.add-cart-product-image{
  display: flex;
  padding: 20pt 0;
  justify-content: center;
}
.add-cart-product-image .add-to-cart-button{
  width: 100%;
  justify-content: center;
  padding: 20pt;
}
.add-cart-product-image .add-to-cart-button:hover{
  transform: scale(0.95);
}
.product-info {
  margin-bottom: 20pt;
}

.customization-section {
  margin-top: 20pt;
}

.customization-section h3{
  color: var(--primary-color);
  border-bottom: 2pt solid var(--primary-color);

  
}
.customization-image {
  max-width: 100%;
  height: auto;
}

@media (min-width: 769px) {
  .product-details-content {
    flex-direction: row;
  }
  .image-column{
    flex: 2;
    max-width: 30%;
  }
  
}

@media (max-width: 768px) {
  .product-details-content {
    flex-direction: column;
  }

  .image-container {
    width: 100%;
  }

  .image-container img {
    width: 100%;
  }

  .zoom-image {
    display: none;
  }

  .image-column{
    flex: 1;
    width: 100% !important;    
  }
}
