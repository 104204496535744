@import './styles/variables.css';

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  background: var(--background-color);
}

#root{
  overflow-y: auto;
}

#root::-webkit-scrollbar {
  height: 12pt;
}
#root::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 1pt;
  cursor: pointer;

}




/* Aplicando um tamanho de fonte de pelo menos 16px nos campos de input */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea {
    font-size: 16px !important;
}