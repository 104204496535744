.invert-icon{
  transform: scaleX(-1)
}
.home {
  display: flex;
  flex-direction: column;
  gap: 20pt;
  color: var(--primary-color);
  text-align: center;
}

.home h2 {
  font-size: 24pt;
}

.home p {
  font-weight: bold;
  text-align: justify;
  word-spacing: 5pt;
}
.titleAreaWithIcons{
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 10pt;  
  color: var(--primary-color);
  text-shadow: 0 4pt 8pt rgb(10, 63, 116, 0.3);
}

.titleAreaWithIcons svg{
  font-size: 18pt;
}

.section {
  padding: 40pt 20pt;
  min-height: 100vh;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0 4pt 8pt rgba(0, 0, 0, 0.5);
}

.contato-header{
  color: var(--primary-color);
  display: flex;
  justify-content: center;
}
#banner {
  background-color: var(--secondary-color);
}



.intro-text{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.intro-text h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
}

.intro-text p {
  font-size: 1.2rem;
  color: var(--dark-color);
  text-align: justify;
  word-spacing: 5pt;
}

.intro-text .btn-primary {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: var(--info-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 5pt;
}
.intro-text .btn-primary:hover {
  background: var(--info-color-hover);
}


.produtos-destaque {
  display: flex;
  gap: 20pt;  
  align-items: stretch;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 60pt 20pt;
}

.destaque-esquerda {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  flex: 1;
  box-sizing: border-box;

}

.destaque-direita {
  flex: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;

}

.destaque-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 15px; /* Reduz o padding para compactar */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;  
}

.destaque-card:hover {
  border: 1pt solid var(--primary-color);
}

.destaque-direita .destaque-card {
  height: 100%; /* Garante que o item da direita ocupe toda a altura disponível */
}

.destaque-card img {
  max-width: 90%; /* Reduz o tamanho máximo da imagem */
  border-radius: 8px;
  margin-bottom: 10px;
}

.destaque-card h2 { 
  margin-bottom: 5pt;
  color: var(--primary-color);
  font-size: 1.5rem;
}

.destaque-card button {
  background-color: var(--info-color);
  color: var(--secondary-color);
  border: none;
  padding: 8px 15px; /* Reduz o padding do botão */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.destaque-card button:hover {
  background-color: var(--info-color-hover);
}





.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline-event {
  padding: 20pt;
  display: flex;
  flex-direction: column;
  align-items: center;  
  margin-bottom: 40px;
  position: relative;
}

.timeline-event:nth-child(even) .timeline-content {
  align-self: flex-start;
}

.timeline-event:nth-child(odd) .timeline-content {
  align-self: flex-end;
}

.timeline-event::after {
  content: '';
  position: absolute;
  width: 4px;
  background-color: var(--info-color);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
  border: 5pt solid var(--info-color);
}

.timeline-event::after {
  content: '';
  position: absolute;
  width: 4px;
  background-color: var(--info-color);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
}

.timeline-event:last-child::after {
  border-bottom-left-radius: 5pt;
  border-bottom-right-radius: 5pt;
}

.timeline-event:first-child::after {
  border-top-left-radius: 5pt;
  border-top-right-radius: 5pt;  
}

/* .timeline-event:last-child::after {
  display: none;
} */

.timeline-content {  
  background-color: var(--secondary-color);
  position: relative;
  border-radius: 5pt;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  width: 60%;
  text-align: left;
  z-index: 1;
}
.timeline-content div:first-child{
  background: var(--dark-color);
}
.timeline-content div:last-child{
  background: var(--light-color);
  padding: 5pt;
}
.timeline-content div{
  display: flex;
  align-items: center;
  gap: 20pt;

}
.timeline-content svg{
  font-size: 34pt;
  color: var(--secondary-color);
  margin-left: 10pt;
}

.timeline-content h3 {
  font-size: 1.5rem;
  color: var(--secondary-color);
}
.timeline-content p {
  text-align: justify;
  color: var(--primary-color);
}


.services-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 50pt;
  padding: 0 20pt;
}

.service-card:nth-child(odd) {flex-direction: row;}
.service-card:nth-child(even) {flex-direction: row-reverse;}


.service-card {
  background-color: var(--secondary-color);
  border-radius: 5pt;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);  
  width: 45%;
  text-align: center;
  display: flex;
  background: var(--secondary-color);
  border: 2pt solid var(--primary-color);
  
}

.service-card div{
  padding: 15pt;
  align-items: center;
  display: flex;
  flex: 1;
}


.service-card:nth-child(odd) div:first-child {
  background: var(--primary-color);
  padding: 10pt;
  color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4pt;  
  justify-content: center;
}

.service-card:nth-child(even) div:first-child {
  background: var(--primary-color);
  padding: 10pt;
  color: var(--secondary-color);
  display: flex;  
  flex-direction: column;
  align-items: center;
  gap: 4pt;  
  justify-content: center;
}
.service-card svg{
  font-size: 34pt;
  color: var(--secondary-color);
}

.service-card h3 {
  font-size: 1.5rem;
  color: var(--secondary-color);
}

.service-card p {
  font-size: 1rem;
  color: var(--dark-color);
  text-align: justify;
}

#introduction {  
  background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.4)),
  url('/public/images/bkgrnd-section-catalog.png') no-repeat center center;
  background-size: cover;
  text-align: center;
  padding: 20pt;
  display: flex;
  flex-direction: row;
}



#contact {
  background-color: var(--secondary-color);
  background: url('/public/images/bkgrnd-section-contact.png') no-repeat center center;  
  background-size: cover;
  text-align: center;
}


#catalog {  
  background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.4)),
  url('/public/images/bkgrnd-section-catalog.png') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#about{  
  /* background: url('/public/images/bkgrnd-section-or-history.png') no-repeat center center;   */  
  background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.4)),
                url('/public/images/bkgrnd-section-or-history.png') no-repeat center center;
                background-size: cover;
}

#services{
  background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.4)),
  url('/public/images/bkgrnd-section-or-service.png') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}


.catalog .btn-secondary {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: var(--info-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 5pt;  
}
.catalog .btn-secondary:hover {
  background-color: var(--info-color-hover);  
}



.contact-options {
  margin-top: 20pt;
  display: flex;
  justify-content: center;
}

.contact-options .btn-whatsapp {
  display: flex;
  align-items: center;
  gap: 20pt;
  margin: 10px 0;
  padding: 12px 20px;
  font-size: 1.2rem;
  background-color: var(--success-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 5pt;
  text-decoration: none;
  box-shadow: 0 4pt 8pt rgba(0, 0, 0, 0.2);
}

.contact-options .btn-whatsapp:hover {
  background: var(--success-color-hover);
}

.no-whatsapp {
  margin-top: 20px;
  font-size: 1rem;
}

.no-whatsapp a {
  color: var(--info-color);
  text-decoration: underline;
}

.faq-section {
  margin-top: 20px;  
  padding-top: 20px;
}

.faq-item {
  margin-bottom: 15px;
  box-shadow: 0 4pt 8pt rgba(0, 0, 0, 0.2);
}

.faq-question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f8f8f8;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.faq-question span{
  display: flex;
  align-items: center;
}
.question{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10pt;
  justify-content: space-between;
}
.question div{
  display: flex;
  gap: 20pt;
  flex-direction: row;
  align-items: center;
}

.faq-answer {
  padding: 10pt;
  background-color: var(--primary-color);
  color: var(--secondary-color);
}


.section-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5pt 0;
}

.section-separator img {
  height: 50pt; /* Tamanho da imagem, ajuste conforme necessário */
  margin: 0 20pt;
}

.section-separator::before,
.section-separator::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: var(--primary-color); /* Cor das linhas */
}

.section-separator::before {
  margin-right: 15pt;
}

.section-separator::after {
  margin-left: 15pt;
}

.question div svg{
  font-size: 20pt;
}

@media (max-width: 768px) {
  .titleAreaWithIcons h1{ 
    font-size: 1.5rem;
  }
  .question div svg{
    font-size: 30pt;
  }
  .intro-text h1 {
    font-size: 2rem;
  }

  .intro-text p {
    font-size: 1rem;
    margin-bottom: 20pt;
  }

  .timeline-content {
    width: 80%;
  }

  .service-card {
    width: 100%;
    min-height: 155pt;
  }

  #introduction{
    flex-direction: column;
  }
  .destaque-card{
    padding: 20pt;
    width: 44%;
  }

  .destaque-card h2 {     
    font-size: 1.0rem;
  }
  .produtos-destaque {
    flex-direction: column;
    align-items: center;
  }

  .destaque-esquerda {
    display: flex;
    flex-direction: row;
    gap: 10pt;    
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .destaque-direita {
    display: flex;
  }

  .destaque-direita .destaque-card {
    height: auto; /* Permite que o item da direita tenha altura automática em dispositivos móveis */
    width: 100%;
  }
}


