/* src/components/SearchBarWithResults.css */
.search-bar-with-results {
    position: relative;
    width: 100%;
    max-width: 600pt;
    margin: 0 auto;
    margin-bottom: 35pt;
  }
  
  .search-input-container {
    display: flex;
    align-items: center;
    border: 2pt solid var(--info-color);    
    overflow: hidden;
    outline: 10pt solid var(--primary-color);
    outline-style: outset;
  }
  
  .search-input-container input {
    width: 100%;
    padding: 10pt;
    border: none;
    outline: none;
    font-size: 18px !important;
  }
  
  .search-icon {
    padding: 10pt;
    background: var(--info-color);
    color: var(--secondary-color);
    font-size: 18pt;
  }
  
  .search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--secondary-color);
    border: 1pt solid var(--primary-color);
    max-height: 200pt;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4pt 8pt rgba(0, 0, 0, 0.1);
  }
  
  .search-result-item {
    display: flex;
    align-items: center;
    padding: 10pt;
    border-bottom: 1pt solid var(--primary-color);
  }
  
  .search-result-item:last-child {
    border-bottom: none;
  }

  .search-result-item p{
    font-weight: 500 !important;
    text-align: left !important;
  }
  
  .search-result-image {
    width: 40pt;
    height: 40pt;
    object-fit: cover;
    margin-right: 10pt;
  }
  
  .search-result-info {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .search-result-info p {
    margin: 0;
    font-size: 12pt;
    color: var(--dark-color);
  }
  
  .search-result-info button {
    background: var(--info-color);
    color: var(--secondary-color);
    border: none;
    padding: 5pt 10pt;
    border-radius: 4pt;
    cursor: pointer;
  }
  
  .search-result-info button:hover {
    background: var(--info-color-hover);
  }

  .search-results::-webkit-scrollbar {
    width: 15pt;
    background-color: var(--info-color);    

  }
  .search-results::-webkit-scrollbar-thumb {
    background-color: var(--info-color-hover);    
    cursor: pointer;    
  }
  
  @media(max-width: 1024px){
    .empty-cart-page .search-bar-with-results{
      display: flex;
      justify-content: center;
      width: 90%;
      margin: 0;
    }
  }