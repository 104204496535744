.whatsapp-button-area{
    width: 50pt;
    height: 50pt;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 52pt;
    color: var(--secondary-color);
    /* background: var(--success-color-hover); */
    background: radial-gradient(circle, var(--success-color-hover) 50%, #fff 53%);
    position: fixed;
    right: 20pt;
    bottom: 30pt;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 1pt -1pt 4pt rgba(0, 0, 0, 0.6);
    z-index: 999;
    transition: transform ease 0.5s;
}

.whatsapp-button-area:hover{ 
    transform: scale(1.05);
}

