/* src/components/ScrollToTopButton.css */
.scroll-to-top {
    position: fixed;
    bottom: 90pt;
    right: 20pt;
    z-index: 1000;
  }
  
  .scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50pt;
    height: 50pt;
    background-color: var(--info-color);
    color: var(--secondary-color);
    font-size: 20pt;
    border-radius: 50%;
    box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  .scroll-button span {
    display: none;
  }
  
  .scroll-button:hover {
    opacity: 0.8;
  }
  
  .scroll-button:hover span {
    display: block;
  }
  