/* src/components/Footer.css */
.footer {
    background-color: var(--black-color);
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    position: relative;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.8);
}
  
  .footer-top {
    display: flex;
    align-items: center;
    gap: 20pt;
    width: 100%;
    align-content: center;
    flex-direction: column;
    justify-content: space-between;
    color: var(--secondary-color);
  }
  
  .footer-logo img {
    width: 200pt;
    height: auto;
  }
  
  .footer-nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 10pt;
    flex-direction: column;
    
  }
  
  .footer-nav a {
    color: var(--secondary-color);
    text-decoration: none;
  }
  
  .footer-nav a:hover {
    text-decoration: underline;
  }
  
  .footer-signature {    
    text-align: center;
  }
  
  .footer-bottom {
    background-color: var(--dark-color);
    width: 100%;
    padding: 10pt;
    text-align: center;
    color: var(--secondary-color);
  }

  .footer-logo {padding: 10pt 0;}
  .footer-atendimento{
    display: flex;
    flex-direction: column;
    gap: 10pt;
  }
  
  @media (min-width: 768px) {
    .footer-top {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .footer-logo,
    .footer-signature {
      flex: 1;
      text-align: center;
    }    
  
    .footer-nav {
      flex: 2;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  