.alerts-container {
  position: fixed;
  top: 20pt;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
}

.alert {
  background-color: var(--secondary-color);
  border: 1pt solid var(--dark-color);
  padding: 10pt;
  margin-bottom: 10pt;
  border-radius: 4pt;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300pt;
  position: relative;
}

.alert-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alert.info {
  background-color: var(--info-color);
  color: var(--secondary-color);
}

.alert.success {
  background-color: var(--success-color);
  color: var(--secondary-color);
}

.alert.error {
  background-color: var(--error-color);
  color: var(--secondary-color);
}

.alert.warning {
  background-color: var(--warning-color);
  color: var(--secondary-color);
}

.close-btn {
  background: none;
  border: none;
  font-size: 16pt;
  cursor: pointer;
  color: var(--secondary-color);
}

.progress-bar {
  height: 4pt;
  background-color: var(--secondary-color);
  transition: width 3s linear;
  border-radius: 2pt;
  bottom: 0;
  position: absolute;
  left: 0;
}
