/* src/styles/variables.css */
:root {
    --primary-color: #0a3f74;
    --secondary-color: #eee;
    --background-color: #e0ebf8;
    --success-color: #28A745;
    --success-color-hover: #178631;
    --error-color: #DC3545;
    --error-color-hover: #b31f2e;
    --warning-color: #FFC107;
    --warning-color-hover:rgb(164, 130, 28)7;
    --info-color: #2872bc;
    --info-color-hover: #135392;
    --light-color: #F8F9FA;
    --dark-color: #777;
    --black-color: #222;    

  }