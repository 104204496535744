/* src/components/SearchBar.css */
.search-bar {
    margin: 10pt 0;
    position: relative;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10pt;
    font-size: 12pt;
    border: 1pt solid var(--primary-color);
    box-sizing: border-box;
    outline: unset;
  }
  
  .search-icon-catalog {
    position: absolute;
    right: 10pt;
    top: 50%;
    transform: translateY(-50%);
    color: var(--dark-color);
  }
  