.carousel .slide .legend
{
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: var(--primary-color);
    color: var(--secondary-color);
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.25;    
}
.carousel .slide .legend h2{
    margin: 5pt 0;
}
.carousel .slide .legend a {
    background: var(--success-color);
    padding: 5pt 15pt;
    cursor: pointer;
}
.carousel .slide .legend a:hover{
    background: var(--success-color-hover);

}
.carousel:hover .slide .legend{
    opacity: 1;
}


.banner-container:hover{    
    opacity: 0.9;
}
.carousel{
    height: 450pt !important;
}
.carousel .slider-wrapper{
    height: 450pt !important;
}
.carousel .slide{
    height: 450pt !important;
}
/* .legend, .carousel-status{
    display: none !important;
} */

@media (max-width: 768px){
    .carousel{
        max-height: 150pt !important;
    }
    .carousel .slider-wrapper{
        max-height: 150pt !important;
    }
    .carousel .slide{
        height: 150pt !important;
    }
    .legend, .carousel-status{
        display: none !important;
    } 
}
