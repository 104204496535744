.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    overflow: hidden;
  }
  
  .modal.show {
    display: flex;
  }
  
  .modal-content {
    background-color: var(--secondary-color);
    padding: 20pt;
    border: 2pt solid var(--primary-color);
    width: 90%;
    max-width: 600pt;
    position: relative;
    border-radius: 8pt;
    box-shadow: 0 5pt 15pt rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
  .modal-content h1, .modal-content h2{
    color: var(--primary-color);
  }
  .modal-content .captcha-container
  {
    display: flex;
    justify-content: center;
  }
  
  .close-button {
    position: absolute;
    top: 10pt;
    right: 20pt;
    font-size: 24pt;
    font-weight: bold;
    cursor: pointer;
    color: var(--primary-color);
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
  }
  
  .modal .form-container input,
  .modal .form-container button,
  .modal .form-container textarea {
    margin: 5pt 0;
    padding: 10pt;
    border: 1pt solid var(--primary-color);
    border-radius: 4pt;
    width: 100%;
    box-sizing: border-box;
    outline: none;
  }
  
  .login-modal-btn-area button,
  .modal button {
    background-color: var(--info-color);
    color: var(--secondary-color);
    cursor: pointer;
    border-radius: 4pt;
    width: 100%;
    box-sizing: border-box;
  }
  
  .login-modal-btn-area button:hover,
  .modal button:hover {
    background-color: var(--primary-color);
  }
  
  .modal p {
    text-align: center;
    color: var(--black-color);
  }
  
  .modal p span {
    color: var(--info-color);
    cursor: pointer;
  }
  
  .password-strength {
    display: flex;
    flex-direction: column;
    margin: 10pt 0;
  }
  
  .password-strength .strength-bar {
    height: 5pt;
    width: 100%;
    background-color: #ddd;
    border-radius: 4pt;
    overflow: hidden;
    margin-top: 5pt;
  }
  
  .password-strength .strength-bar div {
    height: 100%;
    width: 0;
    transition: width 0.3s ease;
  }
  
  .password-strength .strength-text {
    margin-top: 5pt;
  }

  .radio-group {
    display: flex;
    flex-direction: row;
  }

  .radio-group label {
    display: flex;
    flex-direction: row;
    color: var(--dark-color);
    align-items: center;
    margin-left: 10pt;
    gap: 5pt;
  }
  