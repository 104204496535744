/* CheckoutPage.css */
.no-whatsapp{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.no-whatsapp button{
  background-color: var(--info-color);
  padding: 10pt 20pt;
  border: 0;
  border: 1pt solid var(--info-color-hover);
  color: var(--secondary-color);
  font-size: 12pt;
  font-weight: bold;
  cursor: pointer;
}

.no-whatsapp button:hover{
  background: var(--info-color-hover);
}
.checkout-page {
    padding: 20pt;
    color: var(--black-color);
  }
  
  .checkout-form {
    display: flex;
    flex-direction: column;
    gap: 20pt;
    margin-bottom: 40pt;
    background: var(--secondary-color);
    box-shadow: 0 4pt 8pt rgba(0, 0, 0, 0.5);
    padding: 20pt 10pt;
    color: var(--black-color);
  }

  .checkout-form .input-error{
    border: 1pt solid var(--error-color) !important;
  }
  
  .checkout-form label {
    display: flex;
    flex-direction: column;
    gap: 5pt;
  }
  
  .checkout-form input,
  .checkout-form textarea {
    padding: 10pt;
    border: 1pt solid var(--dark-color);
    border-radius: 4pt;
  }
  
  .checkout-form button {
    background-color: var(--info-color);
    color: var(--secondary-color);
    border: none;
    padding: 10pt 20pt;
    border-radius: 4pt;
    cursor: pointer;
  }
  
  .checkout-form button:hover {
    background-color: var(--info-color-hover);
  }
  
  .whatsapp-link {
    margin-top: 20pt;
    text-align: center;
  }
  
  .whatsapp-link a {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 16pt;
    font-weight: bold;
  }
  
  .cart-summary {
    margin-top: 40pt;
  }
  
  .cart-summary h2 {
    color: var(--primary-color);
    margin-bottom: 20pt;
  }
  
  .cart-summary-item {
    display: flex;
    align-items: center;
    gap: 20pt;
    border: 1pt solid var(--dark-color);
    padding: 10pt;
    background: var(--secondary-color);
    margin-bottom: 20pt;
  }
  
  .cart-summary-item img {
    width: 80pt;
    height: 80pt;
    object-fit: cover;
  }
  
  .cart-summary-details {
    display: flex;
    flex-direction: column;
    gap: 10pt;
  }
  
  .cart-summary-details h3 {
    color: var(--primary-color);
    margin: 0;
  }
  
  .cart-summary-details p {
    margin: 0;
  }
  .whatsapp-template
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    padding: 10pt;
    box-shadow: 0 4pt 8pt rgb(23 134 49 / 0.3);
  }
  .whatsapp-template h2{
    color: var(--primary-color);
  }

  .whatsapp-template button{
    background-color: var(--success-color);
    color: var(--secondary-color);
    padding: 10pt;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }

  .whatsapp-template button:hover{
    background-color: var(--success-color-hover);
  }
  
  