/* src/pages/ProfilePage.css */
.profile-page {
    padding: 20pt;    
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
    gap: 10pt;
    padding: 10pt;
    color: var(--primary-color);
    box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.5);
    background: var(--light-color);
  }
  
  .profile-form label {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .profile-form input {
    padding: 10px;
    border: 1px solid var(--dark-color);
    border-radius: 4px;
  }
  
  .profile-form button {
    padding: 10px;
    background-color: var(--info-color);
    color: var(--secondary-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .profile-form button:hover {
    background-color: var(--info-color-hover);
  }
  
  .logout-button {
    padding: 10px;
    background-color: var(--error-color);
    color: var(--secondary-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .logout-button:hover {
    background-color: var(--error-color-hover);
  }
  