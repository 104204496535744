.cart-page {
  display: flex;
  flex-wrap: wrap;
  gap: 20pt;
  padding: 20pt;
  color: var(--black-color);  
  flex-direction: column-reverse;
  min-height: 100vh;
}

.cart-area-left, .cart-area-right {
  flex: 1;
  
}


.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20pt;
}

.cart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10pt;
  border: 1pt solid var(--dark-color);
  padding: 10pt;
  background: var(--light-color);
  box-sizing: border-box;  
}

.cart-item h1, .cart-item h2 {
  color: var(--primary-color);
}

.cart-item img {
  width: 80pt;
  height: 80pt;
  object-fit: cover;
}

.cart-item-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1pt solid var(--dark-color);
  padding: 0 10pt;
}
.cart-img-area{
  display: flex;
  align-items: center;
  height: 100%;
}

.name-description {
  width: 100%;  
  padding: 5pt 0;
  text-align: center;
}

.action-cart-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10pt;
}

.qtd-item-cart {
  display: flex;
  align-items: center;
  gap: 10pt;
  flex-direction: column;  
}

.btn-remove-cart {
  background-color: var(--error-color);
  color: var(--secondary-color);
  border: none;
  padding: 10pt 20pt;
  border-radius: 4pt;
  cursor: pointer;
}

.btn-remove-cart:hover {
  background-color: var(--error-color-hover);
}

.cart-page-summary {
  background: var(--light-color);
  padding: 20pt;  
  box-shadow: 0 4pt 8pt rgba(0, 0, 0, 0.4);
  color: var(--dark-color);
  
}

.cart-page-summary h2 {
  margin-bottom: 10pt;
}

.cart-mesma-linha{
  display: flex;
  flex-direction: row;
  gap: 50pt;
  color: var(--dark-color);
}

.cart-mesma-linha p{
  font-weight: normal !important;

}

.cart-mesma-linha span{
  font-weight: bold;
  color: var(--primary-color);
}

.cart-page-summary ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20pt 0;
}

.cart-page-summary li {
  padding: 3pt 0;
  border-bottom: 1pt solid var(--dark-color);
  display: flex;
  flex-direction: row;
  gap: 10pt;
}

.cart-summary-index{
  min-width: 10%;
}
.cart-page-summary li p {
  font-weight: normal;
  margin: 5pt 0;
}

.cart-page-summary li p span {
  font-weight: bold;
  color: var(--primary-color);
}

.cart-page-summary li:last-child {
  border-bottom: none;
}

.checkout-button {
  background-color: var(--info-color);
  color: var(--secondary-color);
  border: none;
  padding: 10pt 20pt;
  border-radius: 4pt;
  cursor: pointer;
  width: 100%;
  margin-top: 20pt;
}

.checkout-button:hover {
  background-color: var(--info-color-hover);
}

.finalizar-pedido-button{
  display: flex;
  justify-content: center;
  padding: 0 40pt;
}

.empty-cart{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30pt;
  font-size: 144pt;
  color: var(--primary-color);
}

/* Estilos para tablets e telas maiores */
@media (min-width: 768px) {
  .cart-page {
    gap: 40pt;
    flex-direction: column-reverse;
    min-height: 100vh;
  }

  .cart-items {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20pt;    
  }

  .cart-item {
    flex-direction: row;
    align-items: flex-start;
    gap: 20pt;
    width: 100%;
    max-width: 100%;

  }

  .cart-item img {
    width: 100pt;
    height: 100pt;    
  }

  .cart-item-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .name-description {
    flex: 1;    
    padding: 0 10pt;
    text-align: left;
  }

  .action-cart-item {
    flex-direction: row;
    justify-content: flex-end;
    gap: 10pt;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .qtd-item-cart {
    display: flex;
    align-items: center;
    gap: 10pt;
  }

  .qtd-item-cart input, .color-item-cart select {
    flex: 1 1;
    padding: 8pt;
    border: 1pt solid var(--primary-color);
    border-radius: 4pt;
    outline: none;
    box-sizing: border-box;
  }

  .color-item-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10pt;
  }

  .btn-remove-cart {
    padding: 10pt 20pt;
  }

  .checkout-button {
    padding: 10pt 20pt;
    margin-top: 20pt;    
  }
}



@media (min-width: 1024px) {
  .cart-page {
    padding: 30pt;
    flex-direction: column-reverse;
    min-height: 100vh;
  }


  .cart-item {
    gap: 30pt;
    padding: 20pt;
  }

  .cart-item img {
    width: 120pt;
    height: 120pt;
    
  }

  .name-description {
    padding: 0 20pt;
  }

  .btn-remove-cart {
    padding: 10pt 30pt;
  }

  .checkout-button {
    padding: 15pt 30pt;
  }
}

/* Estilos para desktops e telas maiores */
@media (min-width: 1366px) {
  .cart-page {
    padding: 30pt;
    flex-direction: row;
    min-height: 100vh;
  }
}