.cookie-consent {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: var(--secondary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    transition: bottom 5s ease-in-out;
    z-index: 1000;
    box-sizing: border-box;
  }
  
  .cookie-consent.visible {
    bottom: 0;
  }
  
  .cookie-message {
    flex: 3;
    padding: 10px;
  }
  
  .cookie-button {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .cookie-button button {
    padding: 10px 20px;
    border: none;
    background-color: var(--success-color);
    color: var(--secondary-color);
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cookie-button button:hover {
    background-color: var(--success-color-hover);
  }
  
  @media (max-width: 768px) {
    .cookie-consent {
      flex-direction: column;
    }
  
    .cookie-message {
      flex: none;
      text-align: justify;
      padding-bottom: 10px;
    }
  
    .cookie-button {
      flex: none;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  
    .cookie-button button {
      width: 90%;
    }
  }
  