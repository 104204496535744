.add-to-cart-button {
    background-color: var(--success-color);
    color: var(--secondary-color);
    border: none;
    padding: 10pt 20pt;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5pt;
    border-radius: 2pt;
    box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    justify-content: center;

  }
  .add-to-cart-button:hover{
    transform: scale(1.05);
  }
  
  .add-to-cart-button.in-cart {
    background-color: var(--dark-color);
  }
  
  .add-to-cart-button:disabled {
    cursor: not-allowed;
  }
  