.user-quotes-page{
    display: flex;
    flex-direction: column;
    gap: 10pt;
    padding: 10pt;
    color: var(--primary-color);
    
}
.quotes-list{
    display: flex;
    flex-direction: column;
    gap: 10pt   ;
}
.quotes-list h3 span{
    color: var(--dark-color);
}

.quotes-list .Enviado {
    color: var(--secondary-color);
    background: var(--success-color);

}
.quote-summary{
    background: var(--secondary-color);
    box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.2);    
    height: 100pt;
    display: flex;
    flex-direction: row;
}
.description-summary{
    flex: 8;
    padding: 5pt;
}
.empty-description{
    color: var(--dark-color);    
}
.icon-summary{
    flex: 4;    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--info-color);
    color: var(--secondary-color);
    flex-direction: column;
    cursor: pointer;
}

.icon-summary:hover{
    background-color: var(--info-color-hover);

}

.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
}