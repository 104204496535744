/* CatalogPage.css */
@media (min-width: 768px) {
  .catalog-page {    
    flex-direction: row !important;
  }
}
.mobile-filters {
  padding: 0 10pt;
}
.catalog-page {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
}

.filters-column {
  flex: 1;
  max-width: 25%;
  padding: 1rem;
  border-right: 1pt solid var(--secondary-color);
  box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.5);
  background: var(--secondary-color);
  min-height: 100vh;
  position: relative;
  transition: transform 0.3s ease;
}

.filters-column.open {
  transform: translateX(0);
}

.filters-column.closed {
  transform: translateX(-100%);
}

.products-column {
  flex: 3;
  max-width: 75%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: var(--dark-color);
  gap: 10pt;
  position: relative;
}

.products-column.mobile {
  max-width: 100%;
}

.selected-filters {
  flex-wrap: wrap;
  gap: 0.5rem;
  display: flex;
  justify-content: space-around;
  border: 1pt solid var(--primary-color);
  padding: 5pt;
  margin: 5pt 0;
  background: var(--secondary-color);
  box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.2);
}

.selected-filter {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background-color: var(--secondary-color);
  border-radius: 2pt;
  border: 1px dashed var(--dark-color);
}

.selected-filter button {
  margin-left: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.clear-filters {
  margin-left: 1rem;
  background-color: var(--error-color-hover);
  color: var(--secondary-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-sidebar-btn {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--primary-color);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.toggle-sidebar-btn:hover {
  background: var(--secondary-color);
}
