/* src/pages/AdminBannersPage.css */

.one-quarter-size{
  width: 100pt;
}
.admin-banner-form-area{
  background: var(--secondary-color);
  display: flex;
  flex-direction: column;
  padding: 20pt;
  border: 1pt solid var(--dark-color);
  border-radius: 4pt;
  box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.5);

}
.admin-banners-page {
    padding: 20pt;
    color: var(--black-color) !important;
  }
  
  .banner-form {
    display: flex;
    flex-direction: column;
    gap: 10pt;
  }
  
  .banner-form label {
    display: flex;
    flex-direction: column;
    gap: 5pt;
  }
  
  .banner-form input {
    padding: 10pt;
    border: 1pt solid var(--dark-color);
    border-radius: 4pt;
  }
  
  .banner-form button {
    padding: 10pt;
    background-color: var(--success-color);
    color: var(--secondary-color);
    border: none;
    border-radius: 4pt;
    cursor: pointer;
    margin-top: 20pt;    
  }
  
  .banner-form button:hover {
    background-color: var(--success-color-hover);
  }
  .banner-form span{
    font-size: 10pt;
    color: var(--dark-color);
  }
  
  .banner-list {
    margin-top: 20pt;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20pt;
  }
  
  .banner-item {
    background-color: var(--secondary-color);
    padding: 20pt;
    border: 1pt solid var(--dark-color);
    border-radius: 4pt;
    display: flex;
    flex-direction: column;
    gap: 10pt;
    box-shadow: 0pt 4pt 8pt rgba(0, 0, 0, 0.5);

  }
  .admin-image-area{
    width: 100%;
    max-height: 250pt;
  }


  .banner-item img {
    width: 100%;
    border-radius: 4pt;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
  
  .banner-item button {
    padding: 10pt;
    background-color: var(--error-color);
    color: var(--secondary-color);
    border: none;
    border-radius: 4pt;
    cursor: pointer;
    margin: 0 20pt 0 0pt;
  }

  .banner-item .edit-button{
    background: var(--info-color);
  }

  .banner-item .edit-button:hover{
    background: var(--info-color-hover);
  }
  
  .banner-item button:hover {
    background: var(--error-color-hover);
  }
  