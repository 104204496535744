/* src/components/EmailSubscription.css */
.contato-rss {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5pt;
    width: 100%;
    max-width: 300px;
  }
  
  .contato-rss span {
    margin-bottom: 5pt;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .contato-rss form {
    display: flex;
    align-items: center;
    gap: 5pt;
    width: 100%;
  }
  
  .contato-rss input {
    flex: 1;
    padding: 8pt;
    border: 1pt solid var(--primary-color);
    border-radius: 4pt;
    outline: none;
    box-sizing: border-box;
  }
  
  .contato-rss button {
    border: none;
    padding: 8pt 12pt;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-radius: 4pt;
    cursor: pointer;
  }
  
  .error-message {
    color: red;
    font-size: 0.8rem;
    margin-top: 5pt;
  }
  
  .success-message {
    color: green;
    font-size: 0.8rem;
    margin-top: 5pt;
  }
  
  /* Responsivo para dispositivos móveis */
  @media (max-width: 768px) {
    .contato-rss {
      max-width: 100%;
    }
  
    .contato-rss form {
      flex-direction: column;
      gap: 8pt;
    }
  
    .contato-rss input, .contato-rss button {
      width: 100%;
    }
  }
  